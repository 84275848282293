import { Container } from '@mui/material';

import Logo from '../Logo';

function Header() {
  return (
    <Container className="my-8 flex flex-col items-center justify-center md:p-8">
      <Logo />
    </Container>
  );
}

Header.propTypes = {};

export default Header;
