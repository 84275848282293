import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import Header from '../Header/Header';
import ResultsDisplay from '../ResultsDisplay/ResultsDisplay';
import { useGeolocation } from '../../hooks/useGeolocation';
import { findMeRequest } from '../../services/findMeService';
import { useSettings } from '../../context/SettingsContext';

const watch = true;

function AppLocation() {
  const [searchParams] = useSearchParams();

  const referenceNumber = searchParams.get('ref');
  const apiKey = searchParams.get('key');
  const { parameters } = useSettings();

  const [currentStatus, setCurrentStatus] = useState('geolocating');
  const [errorMessage, setErrorMessage] = useState('');

  const [resultData, setResultData] = useState({});

  const { latitude, longitude, accuracy, error } = useGeolocation(watch, {
    enableHighAccuracy: true,
    timeout: parameters.timeout
  });

  useEffect(() => {
    if (!apiKey) {
      setErrorMessage('"key" not specified in URL');
      setCurrentStatus('error');
    } else if (!referenceNumber) {
      setErrorMessage('"ref" not specified in URL');
      setCurrentStatus('error');
    } else if (error) {
      setErrorMessage(`Error Message: ${error}`);
      setCurrentStatus('error');
    } else if (latitude && longitude && !error) {
      setCurrentStatus('found');
      makeRequest();
    } else {
      setCurrentStatus('geolocating');
    }

    return () => {
      // setErrorMessage('');
    };
  }, [latitude, longitude, error]);

  /*
   * Success FindMe API request callback
   * @parameters
   * @param {object} response - The response from the findMeRequest api
   */
  const handleSuccess = (response) => {
    setCurrentStatus('success');
    setResultData({
      latitude,
      longitude,
      accuracy,
      apiKey,
      'Reference Number': referenceNumber,
      address: response.result.address.formatted_address
    });
    setErrorMessage('');
  };

  /*
   * Unsuccessful FindMe API request callback
   * @parameters
   * @param {object} error - The error returned from the findMeRequest api
   */
  const handleError = (error) => {
    if (error?.message) {
      setErrorMessage(`${error.message}`);
      setCurrentStatus('error');
    } else {
      setCurrentStatus('error');
    }
  };

  /*
   * Make a request to the findMeRequest api
   */
  const makeRequest = () => {
    const parameters = {
      latitude,
      longitude,
      accuracy,
      ref: referenceNumber,
      key: apiKey
    };

    findMeRequest(parameters, handleSuccess, handleError, '/location');
  };

  return (
    <Container maxWidth="sm" className="flex h-screen flex-col bg-cover bg-center">
      <Header options="" logoIcon="" className="" />
      <ResultsDisplay
        path="location"
        status={currentStatus}
        errorMessage={errorMessage}
        results={resultData}
        options=""
      />
    </Container>
  );
}

AppLocation.propTypes = {};

export default AppLocation;
