import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DoneIcon from '@mui/icons-material/Done';
import FmdBadIcon from '@mui/icons-material/FmdBad';

import { Grid, Typography } from '@mui/material';
import { useSettings } from '../context/SettingsContext';

function StatusHeading({ status, headerText = '', options, className }) {
  const [currentStatus, setCurrentStatus] = useState();

  useEffect(() => {
    if (status) {
      setCurrentStatus(status);
    }
  }, [status]);

  const renderIcon = (status) => {
    const tempArray = [];
    const iconStyle = ' rounded-full p-1 w-9 h-9 text-slate-300 align-middle';

    switch (status) {
      case 'geolocating':
        tempArray.push(<TravelExploreIcon key={`${status}1`} className={cn(iconStyle, 'bg-cyan-600')} />);
        break;
      case 'success':
        tempArray.push(<DoneIcon key={`${status}2`} className={cn(iconStyle, 'bg-emerald-600')} />);
        break;
      case 'error':
        tempArray.push(<FmdBadIcon key={`${status}3`} className={cn(iconStyle, 'bg-red-600')} />);
        break;
      default:
        tempArray.push(<TravelExploreIcon key={`${status}4`} className={cn(iconStyle, 'bg-cyan-600')} />);
        break;
    }
    return tempArray;
  };

  return (
    <Grid container className="justify-center">
      <Grid item>
        <Typography variant="h5" className={cn(className, 'font-bold text-center align-middle mb-2 pr-2 ')}>
          {renderIcon(currentStatus)}
        </Typography>
      </Grid>
      <Typography variant="h5" className={cn(className, 'font-bold text-center align-middle')}>
        {headerText}
      </Typography>
      <Grid item />
    </Grid>
  );
}

StatusHeading.propTypes = {
  status: PropTypes.string,
  options: PropTypes.object
};

export default StatusHeading;
