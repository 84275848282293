import { useState, useEffect, useRef } from 'react';
import { useSettings } from '../context/SettingsContext';
// ? useGeolocation() input
// ? watch: boolean - set it to true to follow the location.
// ? settings: object - position options
// ? settings.enableHighAccuracy - indicates the application would like to receive the most accurate results (default false),
// ? settings.timeout - maximum length of time (in milliseconds) the device is allowed to take in order to return a position (default Infinity),
// ? settings.maximumAge - the maximum age in milliseconds of a possible cached position that is acceptable to return (default 0).
// ? useGeolocation() output
// ? latitude: number - latitude (i.e. 52.3172414),
// ? longitude: number - longitude (i.e. 4.8717809),
// ? speed: number | null - velocity of the device in meters per second (i.e. 2.5),
// ? timestamp: number - timestamp when location was detected (i.e. 1561815013194),
// ? accuracy: number - location accuracy in meters (i.e. 24),
// ? heading: number | null - direction in which the device is traveling, in degrees (0 degrees - north, 90 degrees - east, 270 degrees - west, and so on),
// ? error: string - error message or null (i.e. User denied Geolocation)
const defaultSettings = {
  enableHighAccuracy: true,
  timeout: Infinity,
  maximumAge: 0
};

export const useGeolocation = (watch = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings
  };

  const { parameters } = useSettings();

  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  // add a references so timeout and geolocator can be closed if there is an error or successful result

  const geolocationRef = useRef(null);
  const timeoutRef = useRef(null);

  const onChange = ({ coords, timestamp }) => {
    // only save position if accuracy is less than the configured accuracy
    if (coords.accuracy < parameters.accuracy) {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        speed: coords.speed,
        heading: coords.heading,
        timestamp
      });

      clearTimeout(timeoutRef.current);
      navigator.geolocation.clearWatch(geolocationRef.current);
    }
  };

  const onError = (error) => {
    clearTimeout(timeoutRef.current);
    navigator.geolocation.clearWatch(geolocationRef.current);

    setError(error.message);
  };

  useEffect(() => {
    if (watch && geolocationRef.current === null) {
      const ref = navigator.geolocation.watchPosition(onChange, onError, settings);

      // create timeout that will error out when the configured timeout is over
      // timeout parameter in geolocation.watchPosition options does not work as expected thus we need to create a timeout ourselves

      const timeout = setTimeout(() => {
        onError({
          code: 1,
          message: 'The time allowed to acquire the geolocation was reached before the information was obtained.'
        });
      }, parameters.timeout);

      geolocationRef.current = ref;
      timeoutRef.current = timeout;
    } else navigator.geolocation.getCurrentPosition(onChange, onError, settings);
  }, []);

  return { ...position, error };
};
