import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import './App.module.css';
import ThemeConfig from './theme';
import logo from './logo.svg';
import SettingsProvider from './context/SettingsContext';
import AppLocation from './components/AppLayout/AppLocation';
import AppRetrieve from './components/AppLayout/AppRetrieve';

function App() {
  return (
    // <AuthProvider>
    <SettingsProvider>
      <ThemeConfig>
        <Routes>
          <Route path="/" element={<AppLocation />} />
          <Route path="/retrieve" element={<AppRetrieve />} />
        </Routes>
      </ThemeConfig>
    </SettingsProvider>
    // </AuthProvider>
  );
}

export default App;
