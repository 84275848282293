/*
 * Take an object and turn it into a query string
 * @parameters
 * @param {object} obj - Object to turn into a query string
 */

const objToQueryString = (obj) => {
  const keyValuePairs = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const element = obj[key];

      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(element)}`);
    }
  }

  return keyValuePairs.join('&');
};

/*
 * Make a request to the findMe api
 * @parameters
 * @param {object} parameters - The parameters with which to make a request
 * @param {func} handleSuccess - Callback for when the request is successful
 * @param {func} handleError - Callback for when the request is unsuccessful
 */

export const findMeRequest = async (parameters, handleSuccess, handleError, path = '/location') => {
  const findMeUrl = process.env.REACT_APP_28EAST_FIND_ME_API_URL;
  const queryString = objToQueryString(parameters);

  try {
    const response = await fetch(`${findMeUrl}${path}?${queryString}`);

    const parsedResponse = await response.json();

    if (parsedResponse?.success) {
      handleSuccess(parsedResponse);
    } else {
      handleError(parsedResponse);
    }
  } catch (error) {
    handleError(error);
  }
};
